
import { flutterHapticFeedback } from "apps.modules.FlutterJavaScriptChannels.ts";

function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export default class Haptics {
    // default
    static async light() {
        flutterHapticFeedback("LightImpact");
    }
    static async medium() {
        flutterHapticFeedback("MediumImpact");
    }
    static async heavy() {
        flutterHapticFeedback("HeavyImpact");
    }
    static async selection() {
        flutterHapticFeedback("SelectionClick");
    }
    static async vibrate() {
        flutterHapticFeedback("Vibrate");
    }

    // custom
    static async success() {
        await this.light();
        await sleep(100);
        await this.light();
        await sleep(100);
        await this.heavy();
    }
    static async warning() {
        await this.medium();
        await sleep(150);
        await this.medium();
    }
    static async error() {
        await this.heavy();
        await sleep(100);
        await this.heavy();
        await sleep(100);
        await this.light();
    }
}
